[
  {
    "title": "Digital Transformation",
    "subtitle": "Our Services",
    "description": "Embrace the future with ECO’s comprehensive digital transformation services. Our tailored strategies and advanced technologies empower your business to evolve, optimize operations, and stay ahead in the rapidly changing digital landscape.",
    "image": "https://images.unsplash.com/photo-1523961131990-5ea7c61b2107?q=80&w=512&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
  },
  {
    "title": "SAP BTP Innovation",
    "subtitle": "Our Services",
    "description": "Unlock the potential of your business with ECO’s SAP Business Technology Platform (BTP) innovation services. Leverage the latest advancements in SAP technology to drive agility, innovation, and growth across your organization.",
    "image": "https://images.unsplash.com/photo-1517181875630-f72350452109?q=80&w=512&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
  },
  {
    "title": "AI & ML Mastery",
    "subtitle": "Our Services",
    "description": "Empower your business with AI and ML solutions crafted by ECO’s experts. From predictive analytics to natural language processing, our mastery in artificial intelligence and machine learning drives innovation and efficiency.",
    "image": "https://images.unsplash.com/photo-1674027444485-cec3da58eef4?q=80&w=512&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
  },
  {
    "title": "RPA Efficiency Experts",
    "subtitle": "Our Services",
    "description": "Optimize your workflows and streamline processes with ECO’s Robotic Process Automation (RPA) expertise. Our efficient automation solutions enhance productivity, reduce errors, and drive cost savings for your business.",
    "image": "https://images.unsplash.com/photo-1542744173-05336fcc7ad4?q=80&w=509&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
  },
  {
    "title": "Elite Support Excellence",
    "subtitle": "Our Services",
    "description": "Experience unparalleled support and service excellence with ECO’s elite support offerings. Our dedicated team ensures smooth operations, timely resolutions, and customer satisfaction at every touchpoint.",
    "image": "https://images.unsplash.com/photo-1553775282-20af80779df7?q=80&w=512&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
  }
]
