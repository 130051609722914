/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const ShapingCard = ({ property1, className, iconSrc, text = "Continuous Innovation" }) => {
  return (
    <div className={`shaping-card property-1-10-${property1} ${className}`}>
      <div className="continuous">{text}</div>
            <i className={`fi ${iconSrc}`} ></i>
    </div>
  );
};

ShapingCard.propTypes = {
  property1: PropTypes.oneOf(["variant-4", "variant-2", "variant-3", "default"]),
  text: PropTypes.string,
};
