import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const PhilosophyCard = ({ text, text1, iconSrc }) => {
  return (
    <div className="philosophy-card">
      <i className={`fi ${iconSrc}`} ></i>
      <div className="frame">
        <div className="OUR-VISION">{text}</div>
        <p className="ECO-has-designed">{text1}</p>
      </div>
    </div>
  );
};

PhilosophyCard.propTypes = {
  text: PropTypes.string.isRequired,
  text1: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};
