import React from "react";
import "./style.css";
import { EcoLogo1 } from "../../icons/EcoLogo1";
import { Link } from "react-router-dom"; // Import Link from React Router

export const Footer = ({ className, icon, phoneNumber }) => {
  return (
    <footer className="footer" id="contact">
      <div className="flex logo-footer">
    <EcoLogo1 className="eco-logo" />
    {/* <div className="links">
      <div className="frame-3">
        <Link to="/"  className="text-wrapper-12">Home</Link>
        <Link to="/#services" className="text-wrapper-13">Our Services</Link>
        <Link to="/#history"  className="text-wrapper-13">Our History</Link>
        <Link to="/#shaping" className="text-wrapper-13">Shaping The Future</Link>
        <Link to="/#success"  className="text-wrapper-13">Your Success</Link>
      </div>
      <div className="frame-3">
        <Link to="/"  className="text-wrapper-12">About</Link>
        <Link to="/about#history"  className="text-wrapper-13">Our History</Link>
        <Link to="/about#philosophy" className="text-wrapper-13">philosophy</Link>
        <Link to="/about#values"  className="text-wrapper-13">Our Values</Link>
        <Link to="/about#alliance"  className="text-wrapper-13">Our Alliance</Link>

      </div>
      <div className="frame-3">
        <Link to="/services"  className="text-wrapper-12">Services</Link>
          </div>
          <div className="frame-3">
        <Link to="/contact"  className="text-wrapper-12">Contact us</Link>
          </div>
    </div> */}
    </div>
    <div className="text-wrapper-13">All Rights Reserved</div>

  </footer>
  );
};
