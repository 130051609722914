import React from "react";
import "./style.css";

export const ServiceSection = ({
  title,
  subtitle,
  description,
  image,
  idx,
}) => {
  return (
    <div className={`service-section ${idx % 2 !== 0 ? "" : "reverse"}`}>
      <img className="preview" src={image} />
      <div className="details">
        <p className="subtitle">
          <small>{subtitle}</small>
        </p>
        <h2 className="title">{title}</h2>
        <p className="description">{description}</p>
      </div>
    </div>
  );
};
