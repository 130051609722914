import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const HistoryCard = ({ property1, className, title, description }) => {
  return (
    <div className={`history-card property-1-7-${property1} ${className}`}>
      <div className="title">{title}</div>
      <p className="paragraph">
        {description}
      </p>
    </div>
  );
};

HistoryCard.propTypes = {
  property1: PropTypes.oneOf(["variant-2", "variant-3", "default"]),
  title: PropTypes.string,
  description: PropTypes.string,
};
