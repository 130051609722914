import React from "react";
import { ContactCard } from "../../components/ContactCard";
import { HistoryCard } from "../../components/HistoryCard";
import { Logos } from "../../components/Logos";
import { Navbar } from "../../components/Navbar";
import { PhilosophyCard } from "../../components/PhilosophyCard";
import { ShapingCard } from "../../components/ShapingCard";
import { Subtitle } from "../../components/Subtitle";
import { Alliance1 } from "../../icons/Alliance1";
import { Footer } from "../../components/Footer";
import content from "./aboutContent.json";
import "./style.css";
import Animated from "../../components/Animated";
import icon1 from "../../img/logo-1.svg";
import icon2 from "../../img/logo-2.svg";
import icon3 from "../../img/logo-3.svg";
import icon4 from "../../img/logo-4.svg";
import icon5 from "../../img/logo-5.svg";
import icon6 from "../../img/logo-6.svg";

export const About = () => {
  return (
    <div className="about">
      <header className="header">
        <Navbar
          aboutUsClassName="design-component-instance-node-2"
          className="design-component-instance-node"
          homeClassName="navbar-instance"
        />
        <div className="hero">
          <div className="text">
            <Animated className="fade-in-bottom">
              <Subtitle
                className="subtitle-instance"
                divClassName="design-component-instance-node-2"
                property1="default"
              />
            </Animated>
            <Animated className="fade-in-bottom">
              <h1 className="h">{content.hero.title}</h1>
            </Animated>
            <Animated className="fade-in-bottom">
              <p className="our-forte-lies-in">{content.hero.description}</p>
            </Animated>
          </div>
        </div>
      </header>
      <div className="our-history" id="history">
        <div className="head">
          <Animated className="fade-in-bottom">
            <Subtitle className="subtitle-2" property1="variant-5" />
          </Animated>
          <Animated className="fade-in-bottom">
            <h2 className="text-wrapper-2">{content.history.title}</h2>
          </Animated>
          <Animated className="fade-in-bottom">
            <p className="we-don-t-just-stop">{content.history.description}</p>
          </Animated>
        </div>
        <Animated className="fade-in-bottom">
          <div className="cards">
            {content.history.items.map((item, index) => (
              <HistoryCard
                key={index}
                className="design-component-instance-node"
                property1="variant-3"
                title={item.title}
                description={item.description}
              />
            ))}
          </div>
        </Animated>
      </div>
      <div className="success" id="philosophy">
        <div className="head">
          <Animated className="fade-in-bottom">
            <Subtitle
              className="subtitle-3"
              divClassName="subtitle-4"
              property1="variant3"
              text={content.philosophy.title}
            />
          </Animated>
          <Animated className="fade-in-bottom">
            <h2 className="text-wrapper-3">{content.philosophy.title}</h2>
          </Animated>
          <Animated className="fade-in-bottom">
            <p className="we-don-t-just-stop-2">
              {content.philosophy.description}
            </p>
          </Animated>
        </div>
        <Animated className="fade-in-bottom">
          <div className="cards-2">
            {content.philosophy.items.map((item, index) => (
              <PhilosophyCard key={index} text={item.text} text1={item.text1} iconSrc={item.icon} />
            ))}
          </div>
        </Animated>
      </div>
      <div className="shaping" id="values">
        <div className="head-2">
          <Animated className="fade-in-bottom">
            <Subtitle
              className="subtitle-2"
              property1="variant-5"
              text={content.values.title}
            />
          </Animated>
          <Animated className="fade-in-bottom">
            <h2 className="text-wrapper-4">{content.values.title}</h2>
          </Animated>
          <Animated className="fade-in-bottom">
            <p className="we-don-t-just-stop-3">{content.values.description}</p>
          </Animated>
        </div>
        <Animated className="fade-in-bottom">
          <div className="cards-3">
            {content.values.items.map((item, index) => (
              <ShapingCard
                key={index}
                className="shaping-card-instance"
                property1={item.property1}
                text={item.text}
                iconSrc={item.icon}
              />
            ))}
          </div>
        </Animated>
      </div>
      <div className="shaping-2" id="alliance">
        <div className="head-3">
          <Animated className="fade-in-bottom">
            <Subtitle
              className="subtitle-2"
              property1="variant-5"
              text={content.alliance.title}
            />
          </Animated>
          <Animated className="fade-in-bottom">
            <h2 className="text-wrapper-2">{content.alliance.title}</h2>
          </Animated>
          <Animated className="fade-in-bottom">
            <p className="we-don-t-just-stop">{content.alliance.description}</p>
          </Animated>
        </div>
   
        <Animated className="fade-in-bottom">
          {/* <Alliance1 className="alliance" /> */}
          <div className="alliance-icons">
          <img src={icon1}/>
          {/* <img src={icon2}/> */}
          <img src={icon3}/>
          <img src={icon4}/>
          <img src={icon5}/>
          <img src={icon6}/>
          </div>

        </Animated>
      
      </div>
      {/* <div className="our-history-2">
        <div className="head-3">
          <Animated className="fade-in-bottom">
            <h2 className="text-wrapper-5">{content.contact.title}</h2>
          </Animated>
          <Animated className="fade-in-bottom">
            <p className="we-don-t-just-stop-4">
              {content.contact.description}
            </p>
          </Animated>
        </div>
        <div className="cards">
          <Animated className="fade-in-bottom">
            <ContactCard className="design-component-instance-node" />
          </Animated>
        </div>
      </div> */}
      <div className="logos-animation">
      <Animated className="fade-in-bottom">
        <Logos className="logos-instance" frameClassName="logos-2" />
      </Animated>
      </div>
      <Footer className="design-component-instance-node"></Footer>
    </div>
  );
};
