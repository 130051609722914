{
  "hero": {
    "title": "About us",
    "description": "Our forte lies in crafting bespoke solutions that harmonize perfectly with SAP, paving the way for your business success. We don’t just stop there."
  },
  "history": {
    "title": "Our History",
    "description": "We don’t just stop there; we also bring to life top-tier, business-centric websites and mobile applications that set you apart in the digital world.",
    "items": [
      {
        "title": "2019",
        "description": "Founded"
    },
    {
        "title": "+75",
        "description": "Satisfied Client"
    },
    {
        "title": "~100%",
        "description": "Clients Retention"
    },
    {
        "title": "6",
        "description": "Segments"
    }
    ]
  },
  "philosophy": {
    "title": "Our Philosophy",
    "description": "We don’t just stop there; we also bring to life top-tier, business-centric websites and mobile applications that set you apart in the digital world.",
    "items": [
      {
        "icon":"fi-rr-eye",
        "text": "OUR Vision",
        "text1": "ECO has designed innovative, well-studied methodologies to become a global leader in providing technology-enabled business solutions and services."
      },
      {
        "icon":"fi-rr-building",
        "text": "OUR Mission",
        "text1": "Our mission is to create superior value for our clients by providing the most qualified business solutions and digital transformation."
      }
    ]
  },
  "values": {
    "title": "Our Values",
    "description": "We don’t just stop there; we also bring to life top-tier, business-centric websites and mobile applications that set you apart in the digital world.",
    "items": [
      {
        "property1": "default",
        "text": "Customer Focus",
        "icon":"fi-rr-circle-user"
      },
      {
        "property1": "variant-2",
        "text": "Industry Focus",
        "icon":"fi-rr-location-crosshairs"
      },
      {
        "property1": "variant-2",
        "text": "Responsibility",
        "icon":"fi-rr-calendar-clock"
      },
      {
        "property1": "default",
        "text": "Commitment to Quality",
        "icon":"fi-rr-shield-check"
      },
      {
        "property1": "variant-2",
        "text": "Innovation",
        "icon":"fi-rr-bulb"
      }
    ]
  },
  "alliance": {
    "title": "Our Alliance",
    "description": "We don’t just stop there; we also bring to life top-tier, business-centric websites and mobile applications that set you apart in the digital world."
  },
  "contact": {
    "title": "Contact us",
    "description": "We don’t just stop there; we also bring to life top-tier, business-centric websites and mobile applications that set you apart in the digital world."
  }
}