/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const Phone = ({ className }) => {
  return (
    <svg
      className={`phone ${className}`}
      fill="none"
      height="44"
      viewBox="0 0 44 44"
      width="44"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect className="rect" fill="#3DA3DB" height="44.0075" rx="10" width="44.0039" />
      <path
        className="path"
        d="M11.8301 11.8175L12.8801 10.9075C14.0901 9.6975 16.0501 9.6975 17.2601 10.9075C17.2901 10.9375 19.1401 13.3475 19.1401 13.3475C20.2801 14.5475 20.2801 16.4375 19.1401 17.6275L17.9801 19.0875C19.4401 22.3975 21.7101 24.6775 24.9101 26.0375L26.3701 24.8675C27.5601 23.7175 29.4601 23.7175 30.6501 24.8675C30.6501 24.8675 33.0601 26.7175 33.0901 26.7475C34.3001 27.9575 34.3001 29.9175 33.1401 31.0775L32.1401 32.2275C30.9901 33.3775 29.4401 34.0075 27.7601 34.0075C20.1201 34.0075 10.0001 23.8775 10.0001 16.2475C10.0001 14.5775 10.6301 13.0175 11.8301 11.8275V11.8175ZM27.7601 31.9975C28.9001 31.9975 29.9501 31.5775 30.6701 30.8475L31.6701 29.6975C32.0801 29.2875 32.1001 28.6175 31.7101 28.1875C31.7101 28.1875 29.3201 26.3475 29.2901 26.3175C28.8801 25.9075 28.1601 25.9075 27.7401 26.3175C27.7101 26.3475 25.7001 27.9575 25.7001 27.9575C25.4201 28.1775 25.0501 28.2375 24.7201 28.1075C20.5801 26.5275 17.6101 23.5675 15.9001 19.2975C15.7701 18.9675 15.8201 18.5875 16.0501 18.2975C16.0501 18.2975 17.6601 16.2775 17.6801 16.2575C18.1101 15.8275 18.1101 15.1375 17.6801 14.7075C17.6501 14.6775 15.8101 12.2875 15.8101 12.2875C15.3801 11.8975 14.7101 11.9075 14.2501 12.3675L13.2001 13.2775C12.4301 14.0475 12.0001 15.0975 12.0001 16.2375C12.0001 23.1975 21.7701 31.9975 27.7601 31.9975Z"
        fill="black"
      />
    </svg>
  );
};
