/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Subtitle = ({ property1, className, divClassName, text = "ECO Digital Solutions" }) => {
  return (
    <div className={`subtitle ${property1} ${className}`}>
      <div className={`custom-SAP-solutions ${divClassName}`}>{text}</div>
    </div>
  );
};

Subtitle.propTypes = {
  property1: PropTypes.oneOf(["default", "variant-5", "variant-2", "variant-3", "variant-4"]),
  text: PropTypes.string,
};
