import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const SupportCard = ({ property1, title, description, iconSrc }) => {
  return (
    <div className={`support-card property-1-12-${property1}`}>
      <i className={`fi ${iconSrc}`} ></i>
      <div className="frame-2">
        <div className="text-wrapper-5">{title}</div>
        <p className="text-wrapper-6">{description}</p>
      </div>
    </div>
  );
};

SupportCard.propTypes = {
  property1: PropTypes.oneOf(["variant-2", "default"]),
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  iconSrc: PropTypes.string.isRequired
};

