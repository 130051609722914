import React, { useEffect, useState } from "react";
import { Button } from "../../components/Button";
import { Input } from "../../components/Input";
import { Navbar } from "../../components/Navbar";
import { Subtitle } from "../../components/Subtitle";
import { EcoLogo1 } from "../../icons/EcoLogo1";

import { Footer } from "../../components/Footer";
import emailjs from "@emailjs/browser";
import "./style.css";
import Animated from "../../components/Animated";

export const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [nameValid, setNameValid] = useState(true);
  const [emailValid, setEmailValid] = useState(true);
  const [messageValid, setMessageValid] = useState(true);
  const [formValid, setFormValid] = useState(true);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    validateForm();
  }, [name, email, message]);

  const validateForm = () => {
    setNameValid(name.trim() !== '');
    setEmailValid(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email));
    setMessageValid(message.trim() !== '');
    setFormValid(nameValid && emailValid && messageValid);
  };

  const handleSendMessage = () => {
    setFormSubmitted(true);
    if (formValid && name && email && message) {
      sendMessage();
    }
  };

  const sendMessage = () => {
    emailjs
      .send(
        "service_qyxv2u5",
        "template_asok9vq",
        {
          from_name: name,
          email,
          message,
        },
        {
          publicKey: process.env.REACT_APP_EMAILJS_PUBLICKEY,
          privateKey: process.env.REACT_APP_EMAILJS_PRIVATEKEY,
        }
      )
      .then(() => {
        setSubmissionSuccess(true);
      })
      .catch((error) => {
        console.error("Error sending message:", error);
      })
      .finally(() => {
        setName("");
        setEmail("");
        setMessage("");
        setNameValid(true);
        setEmailValid(true);
        setMessageValid(true);
        setFormValid(true);
        setFormSubmitted(false);
      });
  };

  return (
    <div className="contact">
      <header className="header">
        <Navbar
          className="design-component-instance-node"
          homeClassName="navbar-instance"
        />
        <div className="hero">
          <div className="text">
            <Animated className="fade-in-bottom">
              <Subtitle
                className="subtitle-instance"
                divClassName="subtitle-2"
                property1="default"
              />
            </Animated>
            <Animated className="fade-in-bottom">
              <div className="h">Contact us</div>
            </Animated>
            <Animated className="fade-in-bottom">
              <p className="our-forte-lies-in">
                Our forte lies in crafting bespoke solutions that harmonize
                perfectly with SAP, paving the way for your <br />
                business success. We don’t just stop there.
              </p>
            </Animated>
          </div>
        </div>
      </header>
      <div className="shaping">
        <div className="head">
          <Animated className="fade-in-bottom">
            <Subtitle className="subtitle-3" property1="variant-5" />
          </Animated>
          <Animated className="fade-in-bottom">
            <p className="let-us-know-more">
              Let us know more about
              <br />
              your business
            </p>
          </Animated>
          <Animated className="fade-in-bottom">
            <p className="we-don-t-just-stop">
              We don’t just stop there; we also bring to life top-tier,
              business-centric websites <br />
              and mobile applications that set you apart in the digital world.
            </p>
          </Animated>
        </div>
        <div className="form">
          <Animated className="fade-in-bottom">
            <Input
              className="input-instance"
              placeholder="Add Your Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {formSubmitted && !nameValid && <p className="error-message">Please enter your name</p>}
          </Animated>
          <Animated className="fade-in-bottom">
            <Input
              className="input-instance"
              placeholder="Add Your Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {formSubmitted && !emailValid && <p className="error-message">Please enter a valid email address</p>}
          </Animated>
          <Animated className="fade-in-bottom">
            <Input
              className="input-instance"
              placeholder="Add Your Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            {formSubmitted && !messageValid && <p className="error-message">Please enter your message</p>}
          </Animated>
          <Animated className="fade-in-bottom">
            <Button
              className="design-component-instance-node"
              property1="default"
              text="CONTACT US"
              onClick={handleSendMessage}
              disabled={!formValid}
            />
          </Animated>
          {submissionSuccess && (
            <div className="chip">Successfully submitted</div>
          )}
        </div>
      </div>
      <Footer className="design-component-instance-node"></Footer>
    </div>
  );
};
