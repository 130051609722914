import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom"; // Import Link from React Router
import "./style.css";

export const Button = ({ property1, className, onClick, link, text }) => {
  return (
    <Link to={link}>
      <button className={`button property-1-0-${property1} ${className}`} onClick={onClick}>
        {text}
      </button>
    </Link>


  );
};

Button.propTypes = {
  property1: PropTypes.oneOf(["variant-2", "default"]),
};
