import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const ServiceCard = ({ property1, content, className }) => {
  return (
    <div className={`service-card ${className}`}>
      <div className={`icon property-1-2-${property1}`}>
        <div className="text-wrapper-3">{content.icon}</div>
      </div>
      <div className={`text property-1-4-${property1}`}>
        <div className="AI-driven-analytics">{content.title}</div>
        <p className="text-wrapper-4">
          {content.description}
        </p>
      </div>
    </div>
  );
};

ServiceCard.propTypes = {
  property1: PropTypes.oneOf(["variant-2", "default"]),
  content: PropTypes.shape({
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  }).isRequired,
  className: PropTypes.string
};
