import React, { useState, useEffect } from "react";
import { Logo } from "../Logo";
import "./style.css";
import { Link } from "react-router-dom"; // Import Link from React Router

export const Navbar = ({ className }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 300;
      setIsScrolled(scrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`navbar ${className} ${isScrolled ? "scrolled" : ""}`}>
          <Link to="/">
      <Logo className="logo-instance" />
      </Link>
      <div className="menu-toggle" onClick={toggleMenu}>
        <div className={`hamburger-icon ${isMenuOpen ? "open" : ""}`}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div className={`menu ${isMenuOpen ? "open" : ""}`}>
        <Link to="/" className="menu-button">
          HOME
        </Link>
        <Link to="/about" className="menu-button">
          ABOUT US
        </Link>
        <Link to="/services" className="menu-button">
          OUR SERVICES
        </Link>
        <Link to="/contact" className="menu-button contact-mobile">
          CONTACT US
        </Link>
      </div>
      <Link to="/contact" className="menu-button contact-web">
        CONTACT US
      </Link>
    </div>
  );
};
