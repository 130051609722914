{
    "subtitle": "Digital Transformation for Your Business",
    "heroText": "",
    "services": {
        "title": "Our Services",
        "description": "Discover Our Service—where exceptional quality and dedicated support converge. ",
        "items": [
            {
                "title": "DIGITAL TRANSFORMATION",
                "description": "Revolutionize your business with our cutting-edge digital transformation services—innovation delivered!",
                "icon":"01"
            },
            {
                "title": "SAP BTP INNOVATION",
                "description": "Unlock the future with SAP BTP Innovation—streamlined, scalable, superior enterprise agility.",
                "icon":"02"
            },
            {
                "title": "AI & ML MASTERY",
                "description": "Empower your business with AI & ML Mastery—transform data into success.",
                "icon":"03"
            },
            {
                "title": "RPA EFFICIENCY EXPERTS",
                "description": "Boost productivity with our RPA Efficiency Experts—smart automation, smarter operations.",
                "icon":"04"
            },
            {
                "title": "ELITE SUPPORT EXCELLENCE",
                "description": "Experience Elite Support Excellence—where premium service meets unparalleled expertise.",
                "icon":"05"
            }
        ]
    },
    "history": {
        "title": "Our History",
        "description": "",
        "items": [
            {
                "title": "2019",
                "description": "Founded"
            },
            {
                "title": "+75",
                "description": "Satisfied Client"
            },
            {
                "title": "~100%",
                "description": "Clients Retention"
            },
            {
                "title": "6",
                "description": "Segments"
            }
        ]
    },
    "shaping": {
        "title": "Shaping The Future",
        "description": "",
        "items":[ 
            {
           "text": "Process Innovation",
           "icon":"fi-rr-bulb"
        },
        {
            "text": "Cloud Transformation",
            "icon":" fi-rr-network-cloud"
         },
         {
            "text": "Enabling Technology",
            "icon":"fi-rr-database"
         },
         {
            "text": "Adaptive Strategy",
            "icon":"fi-rr-plan"
         },
         {
            "text": "Smart Manufacturing",
            "icon":"fi-rr-conveyor-belt-alt"
         },
         {
            "text": "Process Innovation",
            "icon":"fi-rr-bulb"
         },
         {
            "text": "Industry Specific Excellence",
            "icon":"fi-rr-briefcase"
         },
         {
            "text": "Customer Experiences",
            "icon":"fi-rr-life-ring"
         }

    ]
           
    },
    "success": {
        "subtitle": "Unmatched Support Services",
        "title": "Your Success, Our Commitment",
        "description": "",
        "items": [
            {
                "title": "24/7 Customer Support",
                "description": "",
                "icon":"fi-rr-home"
            },
            {
                "title": "Expert Team",
                "description": "",
                "icon":"fi-rr-team-check"
            },
            {
                "title": "Fast Issue Resolution",
                "description": "",
                "icon":"fi-rr-time-fast"
            },
            {
                "title": "Scalability and Growth Support",
                "description": "",
                "icon":"fi-rr-chat-arrow-grow"
            },
            {
                "title": "Customized Support Plans",
                "description": "",
                "icon":"fi-rr-life-ring"
            }
        ]
    }
}