import React from "react";
import { Button } from "../../components/Button";
import { HistoryCard } from "../../components/HistoryCard";
import { Logos } from "../../components/Logos";
import { Navbar } from "../../components/Navbar";
import { ServiceCard } from "../../components/ServiceCard";
import { ShapingCard } from "../../components/ShapingCard";
import { Subtitle } from "../../components/Subtitle";
import { SupportCard } from "../../components/SupportCard";
import { EcoLogo1 } from "../../icons/EcoLogo1";
import content from "./homeContent.json";
import { Footer } from "../../components/Footer";

import "./style.css";
import Animated from "../../components/Animated";

export const Home = () => {
  return (
    <div className="home">
      <Navbar className="design-component-instance-node" />
      <header className="header">
        <div className="hero">
          <div className="text-2">
            <Animated className="fade-in-bottom">
              <Subtitle
                className="subtitle-instance"
                divClassName="span"
                property1="default"
              />
            </Animated>
            <Animated className="fade-in-bottom">
              <h1 className="h">{content.subtitle}</h1>
            </Animated>
            <Animated className="fade-in-bottom">
              <p className="our-forte-lies-in">{content.heroText}</p>
            </Animated>
          </div>
          <Animated className="fade-in-bottom">
            <Button
              className="design-component-instance-node"
              property1="default"
              text="Start your Journey"
              link="/contact"
            />
          </Animated>
        </div>
        <Animated className="fade-in-bottom">
          <Logos className="your-class" frameClassName="your-frame-class" />;
        </Animated>
      </header>

      <div className="our-services" id="services">
        <div className="head">
          <Animated className="fade-in-bottom">
            <Subtitle
              className="design-component-instance-node"
              property1="variant-5"
            />
          </Animated>
          <Animated className="fade-in-bottom">
            <h2 className="our-services-2">{content.services.title}</h2>
          </Animated>
          <Animated className="fade-in-bottom">
            <p className="we-don-t-just-stop">{content.services.description}</p>
          </Animated>
        </div>
          <div className="cards">
            {content.services.items.map((item, index) => (
                      <Animated className="fade-in-bottom">

              <ServiceCard
                key={index}
                className="design-component-instance-node"
                property1="default"
                content={item}
              />
                   </Animated>
            ))}
          </div>
   
      </div>
      <div className="our-history" id="history">
        <div className="head">
          <Animated className="fade-in-bottom">
            <h2 className="text-wrapper-9">{content.history.title}</h2>
          </Animated>
          <Animated className="fade-in-bottom">
            <p className="we-don-t-just-stop-2">
              {content.history.description}
            </p>
          </Animated>
        </div>
        <Animated className="fade-in-bottom">
          <div className="cards-2">
            {content.history.items.map((item, index) => (
              <HistoryCard
                key={index}
                className="design-component-instance-node"
                property1="default"
                title={item.title}
                description={item.description}
              />
            ))}
          </div>
        </Animated>
        <Animated className="fade-in-bottom">
          <Button
            className="design-component-instance-node"
            property1="variant-2"
            text="Start your Journey"
            link="/contact"
          />
        </Animated>
      </div>
      <div className="shaping" id="shaping">
        <div className="head">
          <Animated className="fade-in-bottom">
            <Subtitle className="subtitle-2" property1="variant-5" />
          </Animated>
          <Animated className="fade-in-bottom">
            <div className="text-wrapper-10">{content.shaping.title}</div>
          </Animated>
          <Animated className="fade-in-bottom">
            <p className="we-don-t-just-stop">{content.shaping.description}</p>
          </Animated>
        </div>
        <Animated className="fade-in-bottom">
          <div className="cards-3">
            {content.shaping.items.map((item, index) => (
              <ShapingCard
                key={index}
                className="design-component-instance-node"
                property1={index % 2 === 0 ? "default" : "variant-2"}
                text={item.text}
                iconSrc={item.icon}
              />
            ))}
          </div>
        </Animated>
      </div>
      <div className="success" id="success">
        <div className="head">
          <Animated className="fade-in-bottom">
            <Subtitle
              className="subtitle-3"
              divClassName="subtitle-4"
              property1="default"
              text={content.success.subtitle}
            />
          </Animated>
          <Animated className="fade-in-bottom">
            <h2 className="text-wrapper-11">{content.success.title}</h2>
          </Animated>
          <Animated className="fade-in-bottom">
            <p className="we-don-t-just-stop-2">
              {content.success.description}
            </p>
          </Animated>
        </div>
        <Animated className="fade-in-bottom">
          <div className="cards-4">
            {content.success.items.map((item, index) => (
              <SupportCard
                key={index}
                property1="default"
                title={item.title}
                description={item.description}
                iconSrc={item.icon}

              />
            ))}
          </div>
        </Animated>
      </div>

      <Footer className="design-component-instance-node"></Footer>
    </div>
  );
};
