import { useInViewport } from "ahooks";
import { ReactNode, useRef } from "react";

const Animated = ({ className, options, children }) => {
  const ref = useRef(null);
  const [inViewport] = useInViewport(ref, { threshold: 0, ...options });

  return (
    <div className="anchor" ref={ref}>
      <div className={`${inViewport ? className : ""}`}>{children}</div>
    </div>
  );
};

export default Animated;
