
import React from "react";

export const Location = ({ className }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="44" viewBox="0 0 45 44" fill="none">
    <rect width="44.11" height="44.0021" rx="10" fill="#3DA3DB"/>
    <path d="M22.055 34.002C21.2129 34.0063 20.3821 33.8088 19.6321 33.4261C18.882 33.0433 18.2346 32.4864 17.744 31.802C13.933 26.545 12 22.593 12 20.055C12 17.3882 13.0594 14.8307 14.945 12.945C16.8307 11.0594 19.3882 10 22.055 10C24.7218 10 27.2793 11.0594 29.165 12.945C31.0506 14.8307 32.11 17.3882 32.11 20.055C32.11 22.593 30.177 26.545 26.366 31.802C25.8754 32.4864 25.228 33.0433 24.4779 33.4261C23.7279 33.8088 22.8971 34.0063 22.055 34.002ZM22.055 12.183C19.9674 12.1854 17.966 13.0157 16.4899 14.4919C15.0137 15.968 14.1834 17.9694 14.181 20.057C14.181 22.067 16.074 25.784 19.51 30.523C19.8017 30.9248 20.1844 31.2518 20.6267 31.4773C21.0691 31.7028 21.5585 31.8203 22.055 31.8203C22.5515 31.8203 23.0409 31.7028 23.4833 31.4773C23.9256 31.2518 24.3083 30.9248 24.6 30.523C28.036 25.784 29.929 22.067 29.929 20.057C29.9266 17.9694 29.0963 15.968 27.6201 14.4919C26.144 13.0157 24.1426 12.1854 22.055 12.183Z" fill="black"/>
    <path d="M22.055 16.0005C21.2603 16.0005 20.4834 16.2351 19.8226 16.6747C19.1617 17.1142 18.6467 17.739 18.3426 18.47C18.0384 19.2009 17.9588 20.0053 18.1139 20.7813C18.2689 21.5573 18.6517 22.27 19.2136 22.8295C19.7756 23.389 20.4916 23.77 21.2711 23.9243C22.0506 24.0787 22.8585 23.9994 23.5928 23.6967C24.327 23.3939 24.9546 22.8812 25.3961 22.2233C25.8377 21.5655 26.0734 20.792 26.0734 20.0008C26.0734 18.9399 25.65 17.9224 24.8964 17.1722C24.1428 16.422 23.1207 16.0005 22.055 16.0005ZM22.055 22.001C21.6576 22.001 21.2692 21.8837 20.9388 21.6639C20.6084 21.4441 20.3509 21.1317 20.1988 20.7663C20.0467 20.4008 20.0069 19.9986 20.0845 19.6106C20.162 19.2226 20.3533 18.8662 20.6343 18.5865C20.9153 18.3068 21.2733 18.1163 21.663 18.0391C22.0528 17.9619 22.4568 18.0015 22.8239 18.1529C23.191 18.3043 23.5048 18.5607 23.7256 18.8896C23.9463 19.2185 24.0642 19.6052 24.0642 20.0008C24.0642 20.5313 23.8525 21.0401 23.4757 21.4152C23.0989 21.7903 22.5879 22.001 22.055 22.001Z" fill="black"/>
    </svg>
  );
};
