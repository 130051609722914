import React from "react";

export const Mail = ({ className }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="42" viewBox="0 0 44 42" fill="none">
      <rect width="44" height="42" rx="10" fill="#3DA3DB" />
      <path d="M29 10H15C13.6744 10.0016 12.4036 10.5289 11.4662 11.4662C10.5289 12.4036 10.0016 13.6744 10 15V27C10.0016 28.3256 10.5289 29.5964 11.4662 30.5338C12.4036 31.4711 13.6744 31.9984 15 32H29C30.3256 31.9984 31.5964 31.4711 32.5338 30.5338C33.4711 29.5964 33.9984 28.3256 34 27V15C33.9984 13.6744 33.4711 12.4036 32.5338 11.4662C31.5964 10.5289 30.3256 10.0016 29 10ZM15 12H29C29.5988 12.0012 30.1835 12.1815 30.679 12.5178C31.1744 12.8541 31.5579 13.3309 31.78 13.887L24.122 21.546C23.5584 22.1073 22.7954 22.4225 22 22.4225C21.2046 22.4225 20.4416 22.1073 19.878 21.546L12.22 13.887C12.4421 13.3309 12.8256 12.8541 13.321 12.5178C13.8165 12.1815 14.4012 12.0012 15 12ZM29 30H15C14.2044 30 13.4413 29.6839 12.8787 29.1213C12.3161 28.5587 12 27.7956 12 27V16.5L18.464 22.96C19.4026 23.8963 20.6743 24.422 22 24.422C23.3257 24.422 24.5974 23.8963 25.536 22.96L32 16.5V27C32 27.7956 31.6839 28.5587 31.1213 29.1213C30.5587 29.6839 29.7956 30 29 30Z" fill="black" />
    </svg>
  );
};
