import React from "react";
import "./style.css";
import { Phone } from "../../icons/Phone";
import { Location } from "../../icons/Location";
import { Mail } from "../../icons/Mail";

export const ContactCard = ({ className, icon, phoneNumber }) => {
  return (
    <div className="contact-cards">
      <div className={`contact-card ${className}`}>
      <Phone className="logo-instance" />
      <div className="content">+123 456 7890</div>
      </div>
      <div className={`contact-card ${className}`}>
        <Mail className="logo-instance" />

        <div className="content">sales@eco-ds.com</div>
      </div>
      <div className={`contact-card ${className}`}>
        <Location className="logo-instance" />

        <div className="content">Dammam, Saudi Arabia</div>
      </div>
    </div>
  );
};
