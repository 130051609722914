import React from "react";
import { Navbar } from "../../components/Navbar";
import { ServiceSection } from "../../components/ServiceSection";
import { Subtitle } from "../../components/Subtitle";
import servicesContent from "./servicesContent.json";
import { Footer } from "../../components/Footer";
import "./style.css";
import Animated from "../../components/Animated";

export const Services = () => {
  return (
    <div className="services">
      <header className="header">
        <Navbar
          className="navbar-instance"
          homeClassName="design-component-instance-node"
          ourServicesClassName="design-component-instance-node-2"
        />
        <div className="hero">
          <div className="text">
            <Animated className="fade-in-bottom">
              <Subtitle
                className="subtitle-2"
                divClassName="design-component-instance-node-2"
                property1="default"
              />
            </Animated>
            <Animated className="fade-in-bottom">
              <h1 className="h">Our Services</h1>
            </Animated>
            <Animated className="fade-in-bottom">
              <p className="our-forte-lies-in">
                Our forte lies in crafting bespoke solutions that harmonize
                perfectly with SAP, paving the way for your business success. We
                don’t just stop there.
              </p>
            </Animated>
          </div>
        </div>
      </header>
      <div className="services-cards">
        {servicesContent.map((service, idx) => (
          <>
            <Animated className="fade-in-bottom flex justify-center">
              <ServiceSection {...service} idx={idx} />
            </Animated>
          </>
        ))}
      </div>

      <Footer className="design-component-instance-node"></Footer>
    </div>
  );
};
